<template>
  <div class="page-content has-padding">
    <ui-page-hero title="Vouchers" />

    <section class="section">
      <div class="container">
        <VouchersList />
      </div>
    </section>
  </div>
</template>

<script>
const VouchersList = () => import('@/components/Vouchers/VouchersList')

export default {
  components: {
    VouchersList,
  },

  data() {
    return {}
  },

  computed: {},

  created() {},

  methods: {},
}
</script>

<style lang="scss" scoped></style>
